export default function PrimaryButton({ type = 'submit', className = '', processing, children, onClick }) {
    return (
        <button
            type={type}
            onClick={onClick}
            className={
                `inline-flex items-center px-10 py-3 !bg-secondary hover:shadow-lg border border-white hover:border-slate-200 rounded-md font-semibold text-xs text-white font-bold uppercase hover:text-black active:text-black focus:text-black  hover:bg-slate-200 focus:bg-slate-200 active:bg-slate-200  transition ease-in-out duration-150  +${
                    processing && 'opacity-25'
                } ` + className
            }
            disabled={processing}
        >
            {children}
        </button>
    );
}
